import { Button, Grid, Typography } from '@mui/material';
import { Add, Delete, Edit, Remove } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { ShipmentPrice } from '@dpdgroupuk/consumer-shipping-helper';
import { useSelector } from 'react-redux';
import ShipmentCard from '../../../components/ShipmentCard';
import {
  ADD_TO_CHECKOUT,
  EDIT,
  REMOVE,
  REMOVE_FROM_CHECKOUT,
} from '../../../constants/strings';
import ConfigSlice from '../../../redux/configSlice';

const BasketCard = ({
  basketItem,
  references,
  onRemove,
  basketLength,
  isInCheckoutList,
  onAddToCheckout,
  onRemoveFromCheckout,
}) => {
  const { shipment } = basketItem;
  const priceConfig = useSelector(ConfigSlice.selectors.getPriceConfig);

  const price = ShipmentPrice.calculateShipmentPrice(
    shipment,
    references.networkPrice,
    priceConfig
  ).toViewJSON(2);

  return (
    <ShipmentCard
      shipment={shipment}
      references={references}
      header={
        price.totalIncVatAmount && (
          <Typography variant='h3' color='primary.main'>
            {`£${price.totalIncVatAmount}`}
          </Typography>
        )
      }
      footer={
        <Grid container sx={{ p: 2 }} xs={12}>
          <Grid item display='flex' flexGrow={1} justifyContent='center'>
            <Button
              startIcon={<Edit sx={{ color: 'text.secondary' }} />}
              href={`/order?basketItemId=${basketItem.basketItemId}`}
              sx={{ color: 'text.secondary' }}
            >
              {EDIT}
            </Button>
          </Grid>
          <Grid item display='flex' flexGrow={1} justifyContent='center'>
            <Button
              startIcon={<Delete sx={{ color: 'text.secondary' }} />}
              onClick={onRemove}
              sx={{ color: 'text.secondary' }}
            >
              {REMOVE}
            </Button>
          </Grid>
          {basketLength > 1 && shipment._valid && (
            <Grid
              item
              justifyContent='flex-end'
              sx={{
                flexGrow: { xs: 1, md: 0 },
                marginTop: { xs: 1, md: 0 },
              }}
            >
              <Button
                variant={isInCheckoutList ? 'contained' : 'outlined'}
                onClick={() =>
                  isInCheckoutList
                    ? onRemoveFromCheckout(basketItem.basketItemId)
                    : onAddToCheckout(basketItem.basketItemId)
                }
                startIcon={isInCheckoutList ? <Remove /> : <Add />}
                sx={{ width: { xs: '100%', md: '240px' } }}
              >
                {isInCheckoutList ? REMOVE_FROM_CHECKOUT : ADD_TO_CHECKOUT}
              </Button>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

BasketCard.propTypes = {
  references: PropTypes.shape({
    collectionPickup: PropTypes.object,
    deliveryPickup: PropTypes.object,
    networkPrice: PropTypes.object,
  }),
  basketItem: PropTypes.shape({
    basketItemId: PropTypes.string,
    shipment: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  basketLength: PropTypes.number,
  isInCheckoutList: PropTypes.bool,
  onAddToCheckout: PropTypes.func,
  onRemoveFromCheckout: PropTypes.func,
};

export default BasketCard;
