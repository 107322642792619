import { Box, Button } from '@mui/material';

import { SHARE_TRACKING_INFO } from '../../../constants/strings';
import OrderDetails from '../../../components/OrderDetails';
import EmptySearchResults from '../../../components/EmptySearchResults';
import Loader from '../../../features/Loader';
import { WE_HAVE_A_LITTLE_PROBLEM } from '../../NotFound/constants';
import useParcelSearch from '../../../hooks/useParcelSearch';
import NotFoundMessage from '../../NotFound/components/NotFoundMessage';
import FacePensiveIcon from '../../../components/icons/FacePensiveIcon';

const TrackSection = ({ parcel, parcelNumber, isSmallScreen, onBackClick }) => {
  const { fetchParcel, isParcelFound } = useParcelSearch();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Loader
        promiseFn={fetchParcel}
        pendingProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            flexGrow: 1,
            p: 2,
            mt: { xs: -4, md: 0 },
          },
        }}
        parcelNumber={parcelNumber}
        watch={parcelNumber}
        errorComponent={error => (
          <NotFoundMessage
            title={WE_HAVE_A_LITTLE_PROBLEM}
            descriptionArray={[error.message]}
            mainIcon={<FacePensiveIcon />}
          />
        )}
      >
        {isParcelFound ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
              flexGrow: 1,
              p: 2,
            }}
          >
            <OrderDetails
              parcel={parcel}
              showBackButton={isSmallScreen}
              footerButtons={
                <Button variant='outlined' size='medium'>
                  {SHARE_TRACKING_INFO}
                </Button>
              }
              onBackClick={onBackClick}
            />
          </Box>
        ) : (
          <EmptySearchResults sx={{ mt: { xs: 0, md: 5 }, px: 2 }} />
        )}
      </Loader>
    </Box>
  );
};

export default TrackSection;
