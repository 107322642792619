import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Box, TextField } from '@mui/material';
import _ from 'lodash';
import { validateWithJoi } from '../../utils/validators';
import { normalizeFormValuesForValidation } from '../../utils/addressBook';

const ContactDetailsForm = ({
  values,
  onChange,
  labels = {},
  sx,
  disabled,
  schema,
  inputSize = 'medium',
  form,
  isEditMode,
}) => {
  const [formValues, setFormValues] = useState(values);
  const [errors, setErrors] = useState({});

  const debouncedOnChange = useCallback(
    _.debounce((fieldName, newValues) => {
      const validationErrors = validateWithJoi(newValues, schema);
      setErrors(validationErrors);
      onChange(fieldName, newValues);
    }, 300),
    []
  );

  const onFieldChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const newFormValues = {
      ...formValues,
      [name]: value,
    };
    setFormValues(newFormValues);
    debouncedOnChange(name, newFormValues);
  };

  const hasError = (fieldName, touched) =>
    !disabled && !!touched && !!errors[fieldName];

  const showErrorHelperText = (fieldName, touched) =>
    !disabled && !!touched ? errors[fieldName] : undefined;

  const triggerFormBlur = form => {
    const formFields = form.getRegisteredFields();
    formFields.forEach(name => {
      form.focus(name); // focus first to ensure blur can be triggered
      form.blur(name); // trigger blur event
    });
  };

  //we need to highlight invalid fields on initial form load
  useEffect(() => {
    const validationErrors = validateWithJoi(
      normalizeFormValuesForValidation(formValues),
      schema
    );

    if (isEditMode) {
      setErrors(validationErrors);
      triggerFormBlur(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      noValidate
      autoComplete='off'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...sx,
      }}
    >
      <Field name='contactName'>
        {({ input, meta }) => (
          <TextField
            {...input}
            label={labels.contactName}
            required
            value={formValues.contactName}
            onChange={onFieldChange}
            disabled={disabled}
            error={hasError('contactName', meta.touched)}
            helperText={showErrorHelperText('contactName', meta.touched)}
            size={inputSize}
            onInput={input.onChange}
          />
        )}
      </Field>
      <Field name='organisation'>
        {({ input, meta }) => (
          <TextField
            {...input}
            label={labels.organisation}
            value={formValues.organisation}
            onChange={onFieldChange}
            disabled={disabled}
            error={hasError('organisation', meta.touched)}
            helperText={showErrorHelperText('organisation', meta.touched)}
            size={inputSize}
            onInput={input.onChange}
          />
        )}
      </Field>
      <Field name='nickname'>
        {({ input, meta }) => (
          <TextField
            {...input}
            label={labels.nickname}
            name='nickname'
            required
            value={formValues.nickname}
            onChange={onFieldChange}
            error={hasError('nickname', meta.touched)}
            helperText={showErrorHelperText('nickname', meta.touched)}
            size={inputSize}
            onInput={input.onChange}
          />
        )}
      </Field>
      <Field name='email'>
        {({ input, meta }) => (
          <TextField
            {...input}
            label={labels.email}
            name='email'
            required
            value={formValues.email}
            onChange={onFieldChange}
            error={hasError('email', meta.touched)}
            helperText={showErrorHelperText('email', meta.touched)}
            size={inputSize}
            onInput={input.onChange}
          />
        )}
      </Field>
      <Field name='phoneNumber'>
        {({ input, meta }) => (
          <TextField
            {...input}
            label={labels.phoneNumber}
            name='phoneNumber'
            required
            value={formValues.phoneNumber}
            onChange={onFieldChange}
            error={hasError('phoneNumber', meta.touched)}
            helperText={showErrorHelperText('phoneNumber', meta.touched)}
            size={inputSize}
            onInput={input.onChange}
          />
        )}
      </Field>
    </Box>
  );
};

ContactDetailsForm.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  labels: PropTypes.object,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  schema: PropTypes.object,
  inputSize: PropTypes.oneOf(['small', 'medium']),
  form: PropTypes.object,
  isEditMode: PropTypes.bool,
};

export default ContactDetailsForm;
