export const trimFirstSpace = value => {
  if (typeof value === 'string') {
    return value.replace(/^\s+/, '');
  }
  return value;
};

export const fieldTrimOnBlur = {
  formatOnBlur: true,
  format: value => (typeof value === 'string' ? value.trim() : ''),
};

export const trimAllSpaces = value => value.replace(/\s/g, '');

// Function to inject as a mutator for resetting dependent fields
export const resetFields = ([fields], state, { changeValue }) => {
  fields.forEach(field => changeValue(state, field, () => undefined));
};

// Function to control resetting dependent fields on rules change
export const resetDependentFields =
  fieldDependencyMap => (formApi, fieldName) => {
    const fieldsToReset = fieldDependencyMap[fieldName];

    if (fieldsToReset) {
      formApi.mutators.resetFields(fieldsToReset);
    }
  };

export const resetDependentSections = ({
  sectionDependencyMap,
  sectionFieldsMap,
  reset,
  section,
}) => {
  const sectionsToReset = sectionDependencyMap[section];

  if (!sectionsToReset || sectionsToReset.length === 0) {
    return;
  }

  const fieldsToReset = sectionsToReset.reduce((acc, current) => {
    const fields = sectionFieldsMap[current].map(({ name }) => name);
    return [...acc, ...fields];
  }, []);

  if (fieldsToReset?.length) {
    reset(fieldsToReset);
  }
};
