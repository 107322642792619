import { upperFirst } from 'lodash';
import moment from 'moment-timezone';
import { Divider, Grid, Paper, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import BasketCardDetails from '../BasketCardDetails';
import PickupPointWidget from '../PickupPointwidget';
import {
  CHOSEN_PICKUP_POINT,
  DROP_OFF_POINT,
  PARCEL_TO_$,
  SHIPPING_DETAILS,
  TO_BE_COLLECTED,
  TO_BE_DROPPED_OFF,
} from '../../constants/strings';
import {
  DATE_TIME_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
} from '../../constants/dateFormat';
import { getReceiverContactName } from '../../helpers/shipment';

const ShipmentDefaultCard = ({ shipment, references = {}, header, footer }) => {
  const { outboundConsignment, shipmentDate } = shipment;

  const receiverContactName = getReceiverContactName(shipment);
  const collectionDate = shipmentDate
    ? ` ${moment(shipmentDate, DATE_TIME_FORMAT).format(
        DISPLAY_DATE_TIME_FORMAT
      )}`
    : '';

  const { _dropOffDetails } = outboundConsignment;

  return (
    <>
      <Grid item sx={{ width: '100%', my: 2 }}>
        <Divider variant='middle' sx={{ textTransform: 'uppercase' }}>
          {_dropOffDetails
            ? TO_BE_DROPPED_OFF
            : `${TO_BE_COLLECTED}${collectionDate}`}
        </Divider>
      </Grid>
      <Paper>
        <Grid container sx={{ justifyContent: 'space-between', p: 2 }}>
          <Box>
            <Typography variant='h3'>
              {upperFirst(
                PARCEL_TO_$(
                  receiverContactName,
                  outboundConsignment.parcels.length
                )
              )}
            </Typography>
            <Typography variant='body2'>
              {outboundConsignment._networkDesc}
            </Typography>
          </Box>
          {header}
        </Grid>
        <Divider />
        <Grid container sx={{ p: 2, py: 3 }}>
          <Grid sx={{ mb: 2, pr: { md: 1 }, order: { md: 1 } }} xs={12} md={6}>
            <BasketCardDetails
              data={outboundConsignment.collectionDetails}
              title={SHIPPING_DETAILS.SENDER}
            />
          </Grid>
          <Grid
            sx={{ mb: { xs: 2, md: 0 }, pr: { md: 1 }, order: { md: 3 } }}
            xs={12}
            md={6}
          >
            {references.collectionPickup && (
              <PickupPointWidget
                pickupPoint={{
                  pickupLocation: references.collectionPickup,
                }}
                pickupPointLabel={DROP_OFF_POINT}
                showAddressInLine
              />
            )}
          </Grid>
          <Grid sx={{ mb: 2, pl: { md: 1 }, order: { md: 2 } }} xs={12} md={6}>
            <BasketCardDetails
              data={outboundConsignment.deliveryDetails}
              title={SHIPPING_DETAILS.RECEIVER}
            />
          </Grid>
          <Grid sx={{ pl: { md: 1 }, order: { md: 4 } }} xs={12} md={6}>
            {references.deliveryPickup && (
              <PickupPointWidget
                pickupPoint={{
                  pickupLocation: references.deliveryPickup,
                }}
                pickupPointLabel={CHOSEN_PICKUP_POINT}
                showAddressInLine
              />
            )}
          </Grid>
        </Grid>
        {footer && (
          <>
            <Divider />
            {footer}
          </>
        )}
      </Paper>
    </>
  );
};

ShipmentDefaultCard.propTypes = {
  references: PropTypes.shape({
    collectionPickup: PropTypes.object,
    deliveryPickup: PropTypes.object,
    networkPrice: PropTypes.object,
  }),
  shipment: PropTypes.object,
};

export default ShipmentDefaultCard;
