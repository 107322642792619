import { useParams } from 'react-router';
import { useCallback, useState } from 'react';
import Loader from '../../features/Loader';
import { shipmentsApis } from '../../apis';
import Error from '../Auth/Error';
import Collection from './Collection';
import DropOff from './DropOff';
import { BASKET } from '../../constants/analytics';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { Box } from '@mui/system';
import { CONFIRMATION, VIEW_NEARBY_PICKUPS } from '../../constants/strings';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import ShareModal from '../../components/ShareModal/ShareModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PickupModal from '../../components/PickupModal';
import { getShipmentLabelsDownloadUrl } from './utils/downloadUrl';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const ActionMenuBtn = ({ actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ paddingY: 0 }}
    disableRipple
    aria-label='more'
    onClick={actionMenuBtnHandleClick}
  >
    <MoreVertIcon />
  </IconButton>
);

const Shipment = () => {
  const { shipmentId } = useParams();
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);

  const theme = useTheme();

  const handlePickupPointChange = point => {
    setSelectedPickupPoint(point);
  };
  const actionEditBtnHandleClick = e => {
    setActionMenuAnchorEl(e.currentTarget);
  };
  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };
  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleTabChange = newValue => {
    setSelectedTab(newValue);
  };

  const loadData = useCallback(
    ({ shipmentId }) => shipmentsApis.getShipmentById(shipmentId),
    []
  );

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.pageBackground, pb: 4 }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12}>
              <Grid sx={{ mb: 4, mt: 4 }} item>
                <Typography variant='body1' color=''>
                  {CONFIRMATION.THANK_YOU}
                </Typography>
                <Typography variant='h2' sx={{ mt: 1 }} color=''>
                  {CONFIRMATION.ALL_DONE}
                </Typography>
              </Grid>

              <Grid sx={{ mt: 1 }}>
                <Paper sx={{ p: 0, maxWidth: '400px' }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='h3'>
                      {selectedTab === 0
                        ? CONFIRMATION.PARCEL_PASS_LABEL
                        : CONFIRMATION.HOME_COLLECTION_INSTRUCTIONS}
                    </Typography>
                    <ActionMenuBtn
                      actionMenuBtnHandleClick={actionEditBtnHandleClick}
                    />
                  </Box>

                  <Menu
                    anchorEl={actionMenuAnchorEl}
                    open={!!actionMenuAnchorEl}
                    onClose={actionMenuBtnHandleClose}
                    MenuListProps={{
                      sx: { width: '154px' },
                    }}
                    anchorOrigin={{
                      horizontal: 'left',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                      horizontal: 120,
                      vertical: 90,
                    }}
                  >
                    <StyledMenuItem disableRipple theme={theme}>
                      {CONFIRMATION.DOWNLOAD_INVOICE}
                    </StyledMenuItem>
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={handleOpenShareModal}
                    >
                      {CONFIRMATION.SHARE_PARCEL_INFO}
                    </StyledMenuItem>
                  </Menu>
                  <Divider />

                  <Box
                    sx={{
                      mt: 0,
                      mb: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Tabs
                      sx={{ width: '100%' }}
                      value={selectedTab}
                      onChange={(e, newValue) => handleTabChange(newValue)}
                      TabIndicatorProps={{
                        sx: {
                          backgroundColor: theme.palette.primary.main,
                          height: '2px',
                        },
                      }}
                    >
                      <Tab
                        sx={{
                          width: '50%',
                        }}
                        label={CONFIRMATION.VIEW_QR_CODE}
                      />
                      <Tab
                        sx={{
                          width: '50%',
                        }}
                        label={CONFIRMATION.VIEW_LABEL}
                      />
                    </Tabs>
                  </Box>

                  <Loader
                    promiseFn={loadData}
                    shipmentId={shipmentId}
                    errorComponent={error => <Error values={{ error }} />}
                  >
                    {shipment =>
                      selectedTab === 0 ? (
                        <DropOff shipment={shipment} shipmentId={shipmentId} />
                      ) : (
                        <Collection shipment={shipment} />
                      )
                    }
                  </Loader>

                  <Divider />
                  <Box sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
                    <Button
                      target='_blank'
                      href={getShipmentLabelsDownloadUrl(shipmentId)}
                      sx={{ mx: 1, mb: 0 }}
                      variant='contained'
                    >
                      {CONFIRMATION.DOWNLOAD_LABEL_TO_PRINT}
                    </Button>
                    <PickupModal
                      label={VIEW_NEARBY_PICKUPS}
                      id='pickup-modal'
                      variant='outlined'
                      disabled={false}
                      style={{ mt: 0 }}
                      selectedPickupPoint={selectedPickupPoint}
                      onChange={handlePickupPointChange}
                    />
                    <Button
                      href='/order'
                      sx={{
                        mx: 1,
                        mb: 2,
                        mt: 1,
                        color: theme.palette.primary.main,
                      }}
                      variant='none'
                    >
                      {CONFIRMATION.SEND_ANOTHER_PARCEL}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

export default Shipment;
