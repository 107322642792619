import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import useAnalytics from '../../utils/useAnalytics';
import { TRACK } from '../../constants/analytics';
import { TRACK as TRACK_PATH } from '../../constants/routes';
import { FIELDS } from '../../constants/forms';
import parcelSlice from '../../redux/parcelSlice';
import useBreakpoint from '../../hooks/useBreakpoint';
import { InitialSection, Sidebar, TrackSection } from './components';

const Track = () => {
  const isSmallScreen = useBreakpoint('md');

  const { setInterfaceId, Trackable } = useAnalytics();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToTrack = () => navigate(TRACK_PATH);

  const parcelNumber = searchParams.get(FIELDS.PARCEL_NUMBER.KEY);

  const { data: parcel } = useSelector(
    parcelSlice.selectors.getParcel(parcelNumber)
  );

  useEffect(() => {
    setInterfaceId(TRACK.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={TRACK.LOAD}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          height: '100%',
          flexGrow: 1,
        }}
      >
        {!(isSmallScreen && !!parcel) && (
          <Sidebar parcelNumber={parcelNumber} />
        )}
        {parcelNumber ? (
          <TrackSection
            parcel={parcel}
            parcelNumber={parcelNumber}
            isSmallScreen={isSmallScreen}
            onBackClick={navigateToTrack}
          />
        ) : (
          <InitialSection />
        )}
      </Box>
    </Trackable>
  );
};

export default Track;
