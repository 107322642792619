export const ADDRESS_BOOK_INITIAL_FORM_VALUES = {
  address: {
    countryCode: 'GB',
    postcode: '',
    county: '',
    town: '',
    street: '',
    locality: '',
  },
  contactName: '',
  organisation: '',
  nickname: '',
  email: '',
  phoneNumber: '',
};
