/* eslint-disable max-lines */
/* eslint-disable complexity */
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Field } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import { Add, Delete } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { cloneDeep, debounce, get, remove } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDeepCompareEffect } from 'react-use';

import Debugger from '../../../../components/Debugger/Debugger';
import * as STRINGS from '../../../../constants/strings';
import useAnalytics from '../../../../utils/useAnalytics';
import { PARCEL_DETAILS } from '../../../../constants/analytics';
import { SummaryDetails } from '../../../../components/SummaryDetails';
import { networksActions } from '../../../../redux/networksSlice';
import {
  SHIPMENT_FIELDS,
  FIELDS,
  DELIVERY_DETAILS_KEY,
  COLLECTION_DETAILS_KEY,
} from '../../../../constants/forms';
import ActionButton from '../../../../components/ActionButton';
import MaxParcelDimsModal from '../../../../components/MaxParcelDimsModal/MaxParcelDimsModal';
import {
  getNetworksSchema,
  requireKeysSchema,
  validateWithJoi,
} from '../../../../utils/validators';
import PickupModal from '../../../../components/PickupModal';
import { trim } from '../../../../utils/formatters';
import WizardForm from '../../../../features/Wizard/Form';
import AddressDetailsForm from '../../../../components/AddressDetailsForm/AddressDetailsForm';
import {
  getAdditionalPriceForNetwork,
  getMinDoorPriceFromNetworks,
  getMinShopPriceFromNetworks,
  getParcelsTotalWeight,
  getParcelTotalQuantity,
} from '../../../../utils/networks';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import PickupPointWidget from '../../../../components/PickupPointwidget/PickupPointWidget';
import { BasketActions } from '../../../../redux/basket';
import { POINT_TYPE } from '../../../../constants';
import { useOverlay } from '../../../../features/Overlay';
import { ShipmentValidator } from '../../validators';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import { PickupHelper, ShipmentHelper } from '../../helpers';
import { useReferencesLoader } from '../../hooks';
import { FREE_PROTECTION_DEDUCTION, REFERENCE_NAME } from '../../constants';
import { DATE_TIME_FORMAT } from '../../../../constants/dateFormat';
import { trimAllSpaces } from '../../../../utils/formHelpers';
import AddressBookModal from '../../../../components/AddressBookModal';
import * as OrderSelectors from '../../selectors';
import configSlice from '../../../../redux/configSlice';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import Loader from '../../../../features/Loader';
import * as StepSelectors from './selectors';
import { fetchCollectionDates } from './actions';
import { SECTION, SECTION_FIELDS_MAP } from './constants';
import FormSection from './components/FormSection';

const ParcelDetails = ({
  nextStep,
  values,
  references,
  setReferences,
  setValues,
}) => {
  const navigate = useNavigate();
  const { setInterfaceId, Trackable } = useAnalytics();
  const theme = useTheme();
  const overlay = useOverlay();
  const isSmallScreen = useBreakpoint('md');
  const [searchParams, setSearchParams] = useSearchParams();
  const snackbar = useCustomSnackbar();
  const referencesLoader = useReferencesLoader();

  const { networks } = references;
  const filteredNetworks = useSelector(StepSelectors.getFilteredNetworks);
  const selectedNetwork = useSelector(StepSelectors.getSelectedNetwork);
  const parcelsInvalid = useSelector(StepSelectors.validateParcelsValues);
  const shipToShopAvailable = useSelector(StepSelectors.getShipToShopAvailable);
  const dataForGetNetworks = useSelector(
    StepSelectors.getOutboundNetworkParameters
  );
  const defaultCutOff = useSelector(
    configSlice.selectors.getCollectionCutOffConfig
  );

  const dispatch = useDispatch();
  const { errorHandler } = useErrorHandler();
  const [maxDimsModalOpen, setMaxDimsModalOpen] = useState(false);

  useEffect(() => {
    setInterfaceId(PARCEL_DETAILS.INTERFACE_ID);
    overlay.show();

    const loadReferences = async () => {
      try {
        await Promise.all([
          referencesLoader.loadDropOffCollection(),
          referencesLoader.loadDeliveryPickup(),
        ]);
      } catch (error) {
        errorHandler(STRINGS.PARCEL_FETCHING_ERROR, error);
      } finally {
        overlay.hide();
      }
    };

    loadReferences();
  }, [
    referencesLoader.loadDropOffCollection,
    referencesLoader.loadDeliveryPickup,
  ]);

  const onSubmit = useCallback(async formValues => {
    try {
      overlay.show();
      const totalWeight = getParcelsTotalWeight(
        formValues.outboundConsignment.parcels
      );

      const numberOfParcels = getParcelTotalQuantity(
        formValues.outboundConsignment.parcels
      );

      let newShipment = {
        ...formValues,
        outboundConsignment: {
          ...formValues?.outboundConsignment,
          totalWeight,
          numberOfParcels,
        },
      };

      setValues(newShipment);

      let basketItemId = searchParams.get('basketItemId');
      const updatedBasketItem = {
        basketItemId: basketItemId,
        shipment: newShipment,
      };

      if (basketItemId) {
        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();
      } else {
        const { basketItemId: itemId } = await dispatch(
          BasketActions.createBasketItem(newShipment)
        ).unwrap();
        basketItemId = itemId;
      }
      setSearchParams({ ...searchParams, basketItemId });

      nextStep();
    } catch (e) {
      snackbar.showError({
        message: STRINGS.FAILED_TO_SAVE_BASKET,
      });
    } finally {
      overlay.hide();
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate('/');
  }, [navigate]);

  // PRICING LOGIC
  const dropAtShop = get(values, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY);
  const collectionPostcode = get(
    values,
    'outboundConsignment.collectionDetails.address.postcode'
  );
  const collectionCountryCode = get(
    values,
    'outboundConsignment.collectionDetails.address.countryCode'
  );

  const debounceTime = 300;

  const debouncedFetchNetworks = useCallback(
    debounce(dataForGetNetworks => {
      dispatch(networksActions.fetchNetworks(dataForGetNetworks))
        .unwrap()
        .then(networks => {
          setReferences('networks', networks);
        })
        .catch(() => {
          snackbar.showError({
            message: STRINGS.NO_NETWORKS_ERROR,
            persist: true,
          });
          setReferences('networks', []);
        });
    }, debounceTime),
    []
  );

  useDeepCompareEffect(() => {
    const { error } = getNetworksSchema.validate(dataForGetNetworks);

    if (!error) {
      debouncedFetchNetworks(dataForGetNetworks);
    }
  }, [dataForGetNetworks]);

  const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
  const shipmentDate = get(values, SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
  const senderAddressDisabled = !get(
    values,
    SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
  );
  const parcelSizeDisabled =
    senderAddressDisabled ||
    !get(values, SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY) ||
    !networks?.length;
  const parcelCollectionAvailable =
    networks.some(({ networkKey }) => networkKey !== '2^91') &&
    get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY) !== POINT_TYPE.SHOP;
  const parcelDestinationDisabled =
    !networks?.length || parcelSizeDisabled || parcelsInvalid;
  const parcelCollectionDisabled =
    parcelDestinationDisabled || !get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY);
  const networkDisabled =
    parcelDestinationDisabled ||
    !collectFrom ||
    (collectFrom === POINT_TYPE.DOOR && !shipmentDate);
  const insuranceVisible =
    selectedNetwork && selectedNetwork.insurance && !networkDisabled;
  const activeCollectionPickupPoint =
    references[REFERENCE_NAME.COLLECTION_PICKUP];
  const activeDestinationPickupPoint =
    references[REFERENCE_NAME.DELIVERY_PICKUP];
  // review
  const context = useSelector(StepSelectors.getMaxWeightAndNumberOfParcels);
  const requiredKeys = useSelector(
    StepSelectors.getParcelDetailsRequiredFields
  );
  const requiredKeysMap = useSelector(
    StepSelectors.getParcelDetailsRequiredFieldsMap
  );
  const collectionDateAvailable = collectFrom === POINT_TYPE.DOOR;

  const price = useSelector(OrderSelectors.getTotalAmount);

  const minShopPrice = getMinShopPriceFromNetworks(filteredNetworks);
  const minDoorPrice = getMinDoorPriceFromNetworks(filteredNetworks);

  const loadCollectionDates = useCallback(
    async ({ form, collectionCountryCode, collectionPostcode }) => {
      const data = await dispatch(
        fetchCollectionDates({
          countryCode: collectionCountryCode,
          postcode: collectionPostcode,
        })
      ).unwrap();

      const cutOffDates = ShipmentHelper.getCutOffDates(data);

      const dates = cutOffDates.map(({ date, collectionCutOff }) =>
        ShipmentHelper.formatShipmentDate({
          date,
          collectionCutOff: collectionCutOff || defaultCutOff,
        })
      );

      form.change(SHIPMENT_FIELDS.SHIPMENT_DATE.KEY, dates[0]);

      return dates;
    },
    []
  );

  const parcelDetailsSchema = requireKeysSchema(
    ShipmentValidator.shipmentSchema(),
    requiredKeys
  );

  const validate = useCallback(
    values =>
      validateWithJoi(values, parcelDetailsSchema, {
        allowUnknown: true,
        context,
      }),
    [parcelDetailsSchema, context]
  );

  const onSelectFromAddressBook = (form, selectedAddress, field) => {
    const {
      addressBookId,
      countryCode,
      addressLine1,
      addressLine2,
      addressLine3: town,
      addressLine4: county,
      postcode,
      contactName,
      organisation,
      phoneNumber,
      email,
    } = selectedAddress;

    const addressBookFormValues = {
      address: {
        [FIELDS.ADDRESS_KEY.KEY]: '',
        _addressBookId: addressBookId,
        countryCode,
        street: addressLine1,
        locality: addressLine2,
        town,
        county,
        postcode,
        organisation: organisation || contactName,
        coordinates: {
          latitude: selectedAddress.latitude || '0',
          longitude: selectedAddress.longitude || '0',
        },
      },
      contactDetails: {
        contactName,
        telephone: phoneNumber,
        email,
      },
    };

    form.change(field, addressBookFormValues);
  };

  return (
    <Trackable loadId={PARCEL_DETAILS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <WizardForm onSubmit={onSubmit} validate={validate} validateOnBlur>
          {({ handleSubmit, values, invalid, form, errors }) => {
            return (
              <>
                <Grid item xs={12} md={8}>
                  <form id='parcelDetailsForm' onSubmit={handleSubmit}>
                    <FormSection
                      title={STRINGS.WHERE_YOUR_PARCEL_GOES}
                      section={SECTION.DELIVERY_ADDRESS}
                      related={[
                        SECTION.DELIVER_TO,
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <AddressDetailsForm
                        values={values}
                        config={{
                          fields: {
                            country: {
                              name: SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_COUNTRY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
                                ],
                            },
                            postcode: {
                              name: SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_POSTCODE.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                                ],
                            },
                            organisation: {
                              name: SHIPMENT_FIELDS.DESTINATION_ORGANISATION
                                .KEY,
                              label:
                                SHIPMENT_FIELDS.DESTINATION_ORGANISATION.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
                                ],
                            },
                            street: {
                              name: SHIPMENT_FIELDS.DESTINATION_STREET.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_STREET.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_STREET.KEY
                                ],
                            },
                            locality: {
                              name: SHIPMENT_FIELDS.DESTINATION_LOCALITY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_LOCALITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_LOCALITY.KEY
                                ],
                            },
                            town: {
                              name: SHIPMENT_FIELDS.DESTINATION_CITY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_CITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_CITY.KEY
                                ],
                            },
                            county: {
                              name: SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_COUNTY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY
                                ],
                            },
                          },
                          hasActionButtons: true,
                          idPrefix: 'delivery',
                        }}
                      />
                      <AddressBookModal
                        sx={{ mt: 2 }}
                        onSelect={selectedAddresses =>
                          onSelectFromAddressBook(
                            form,
                            selectedAddresses,
                            DELIVERY_DETAILS_KEY
                          )
                        }
                      />
                    </FormSection>
                    <FormSection
                      title={STRINGS.WHERE_YOUR_PARCEL_FROM}
                      section={SECTION.COLLECTION_ADDRESS}
                      disabled={senderAddressDisabled}
                      related={[
                        SECTION.DELIVER_TO,
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <AddressDetailsForm
                        values={{
                          ...values,
                          // Only GB should be available in Send From countries
                          [SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY]: 'GB',
                        }}
                        config={{
                          availableCountryCodes: ['GB'],
                          fields: {
                            country: {
                              name: SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_COUNTRY.LABEL,
                              // Disable change to make only GB set
                              disabled: !!get(
                                values,
                                SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
                              ),
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
                                ],
                            },
                            postcode: {
                              name: SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_POSTCODE.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
                                ],
                            },
                            organisation: {
                              name: SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
                              label:
                                SHIPMENT_FIELDS.COLLECTION_ORGANISATION.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                                ],
                            },
                            street: {
                              name: SHIPMENT_FIELDS.COLLECTION_STREET.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_STREET.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_STREET.KEY
                                ],
                            },
                            locality: {
                              name: SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_LOCALITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY
                                ],
                            },
                            town: {
                              name: SHIPMENT_FIELDS.COLLECTION_CITY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_CITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_CITY.KEY
                                ],
                            },
                            county: {
                              name: SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_COUNTY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY
                                ],
                            },
                          },
                          hasActionButtons: true,
                          idPrefix: 'collect',
                          addressBookConfig: {
                            customization: {
                              // Disable all non 'GB' addresses in address book
                              rowStyle: item =>
                                item.countryCode !== 'GB'
                                  ? { opacity: 0.5, pointerEvents: 'none' }
                                  : undefined,
                            },
                          },
                        }}
                        disabled={senderAddressDisabled}
                      />
                      <AddressBookModal
                        sx={{ mt: 2 }}
                        onSelect={selectedAddresses =>
                          onSelectFromAddressBook(
                            form,
                            selectedAddresses,
                            COLLECTION_DETAILS_KEY
                          )
                        }
                        config={{
                          customization: {
                            // Disable all non 'GB' addresses in address book
                            rowStyle: item =>
                              item.countryCode !== 'GB'
                                ? { opacity: 0.5, pointerEvents: 'none' }
                                : undefined,
                          },
                        }}
                      />
                    </FormSection>
                    {/* parcels section */}
                    {values?.outboundConsignment?.parcels?.map(
                      (parcel, index) => (
                        <FormSection
                          key={index}
                          section={SECTION.PARCEL}
                          title={STRINGS.HOW_BIG_IS_YOUR_PARCEL}
                          disabled={parcelSizeDisabled}
                          related={[
                            SECTION.DELIVER_TO,
                            SECTION.COLLECT_FROM,
                            SECTION.SHIPMENT_DATE,
                            SECTION.NETWORK,
                            SECTION.INSURANCE,
                          ]}
                        >
                          <Grid
                            container
                            sx={{
                              my: { xs: 1, md: 2 },
                              mt: { md: 3 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.WEIGHT}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_WEIGHT_$(
                                  context.maxWeight || 30
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_WEIGHT.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                inputProps={{
                                  inputmode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                fieldProps={{
                                  parse: trimAllSpaces,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.HEIGHT}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_HEIGHT_$(100)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_HEIGHT.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_HEIGHT.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                inputProps={{
                                  inputmode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                fieldProps={{
                                  parse: trimAllSpaces,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.WIDTH}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_WIDTH_$(100)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_WIDTH.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_WIDTH.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                inputProps={{
                                  inputmode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                fieldProps={{
                                  parse: trimAllSpaces,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.LENGTH}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_LENGTH_$(100)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_LENGTH.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_LENGTH.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                inputProps={{
                                  inputmode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                fieldProps={{
                                  parse: trimAllSpaces,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.QUANTITY}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.QUANTITY_DESCRIPTION}
                              </Typography>
                              <br />
                              <Typography variant='caption'>
                                {STRINGS.MAX_QUANTITY_$(
                                  context.maxParcelNumber || 99
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                  index
                                )}
                                label={SHIPMENT_FIELDS.PARCEL_QUANTITY.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                inputProps={{
                                  inputmode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                fieldProps={{
                                  parse: trimAllSpaces,
                                }}
                              />
                              {[
                                SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index),
                                SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(index),
                              ].map(field => (
                                // eslint-disable-next-line react/jsx-key
                                <OnBlur name={field}>
                                  {async () => {
                                    // TODO: Refactor
                                    await setReferences(
                                      REFERENCE_NAME.DELIVERY_PICKUP,
                                      null
                                    );
                                    setReferences(
                                      REFERENCE_NAME.COLLECTION_PICKUP,
                                      null
                                    );
                                  }}
                                </OnBlur>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid container sx={{ my: 1 }}>
                            <Grid item>
                              <Button
                                sx={{ px: 0 }}
                                variant='text'
                                onClick={() => setMaxDimsModalOpen(true)}
                                disabled={parcelSizeDisabled}
                              >
                                {STRINGS.VIEW_MAX_PARCEL_SIZES}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid sx={{ my: 1.5 }}>
                            <Grid item>
                              <Typography variant='body1'>
                                {STRINGS.PARCEL_DESCRIPTION}
                              </Typography>
                            </Grid>
                          </Grid>
                          <TextFieldRFF
                            sx={{ mb: 2 }}
                            name={SHIPMENT_FIELDS.PARCEL_DESCRIPTION.KEY(index)}
                            size='small'
                            label={SHIPMENT_FIELDS.PARCEL_DESCRIPTION.LABEL}
                            fullWidth
                            disabled={parcelSizeDisabled}
                            fieldProps={{
                              formatOnBlur: true,
                              format: trim,
                            }}
                          />
                          <Grid
                            container
                            sx={{
                              mt: 1,
                              flexDirection: {
                                xs: 'column-reverse',
                                md: 'row',
                              },
                              justifyContent: {
                                xs: 'space-between',
                                md: 'flex-end',
                              },
                            }}
                          >
                            {values?.outboundConsignment?.parcels?.length >
                              1 && (
                              <Button
                                variant={'outlined'}
                                onClick={() => {
                                  const newParcels = cloneDeep(
                                    values?.outboundConsignment?.parcels
                                  );
                                  remove(
                                    newParcels,
                                    item => item.parcelId === parcel.parcelId
                                  );
                                  form.change(
                                    SHIPMENT_FIELDS.PARCELS.KEY,
                                    newParcels
                                  );
                                  SECTION_FIELDS_MAP.splice(
                                    values?.outboundConsignment?.parcels
                                      ?.length > 1,
                                    1
                                  );
                                }}
                                disabled={parcelSizeDisabled}
                                startIcon={<Delete />}
                                sx={{ marginTop: { xs: 1, md: 0 } }}
                              >
                                {STRINGS.DELETE_PARCEL}
                              </Button>
                            )}
                            {index ===
                              values?.outboundConsignment?.parcels?.length -
                                1 && (
                              <Button
                                variant='outlined'
                                onClick={() => {
                                  const newParcels = cloneDeep(
                                    values?.outboundConsignment?.parcels
                                  );
                                  newParcels.push({
                                    parcelId: crypto.randomUUID(),
                                  });
                                  form.change(
                                    SHIPMENT_FIELDS.PARCELS.KEY,
                                    newParcels
                                  );
                                  SECTION_FIELDS_MAP.parcel.push(
                                    ...[
                                      {
                                        name: SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(
                                          values?.outboundConsignment?.parcels
                                            ?.length
                                        ),
                                      },
                                      {
                                        name: SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                          values?.outboundConsignment?.parcels
                                            ?.length
                                        ),
                                      },
                                    ]
                                  );
                                }}
                                disabled={parcelSizeDisabled}
                                startIcon={<Add />}
                                sx={{ ml: { xs: 0, md: 1 } }}
                              >
                                {STRINGS.ADD_ANOTHER_PARCEL}
                              </Button>
                            )}
                          </Grid>
                        </FormSection>
                      )
                    )}
                    <FormSection
                      title={STRINGS.WHERE_DELIVER_PARCEL_TO}
                      section={SECTION.DELIVER_TO}
                      disabled={parcelDestinationDisabled}
                      related={[
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <Field name={SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, alignItems: 'stretch' }}
                          >
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.SHOP}
                                highlightChip={STRINGS.MOST_POPULAR}
                                title={STRINGS.DIRECT_TO_PICKUP}
                                bodyText={STRINGS.DIRECT_TO_PICKUP_DESCRIPTION}
                                price='Free'
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.SHOP);
                                }}
                                disabled={
                                  parcelDestinationDisabled ||
                                  !shipToShopAvailable
                                }
                              />
                              <PickupModal
                                label={STRINGS.VIEW_NEARBY_PICKUPS}
                                id='view-collection-pickups'
                                variant='text'
                                disabled={
                                  input.value !== POINT_TYPE.SHOP ||
                                  parcelDestinationDisabled ||
                                  !shipToShopAvailable
                                }
                                selectedPickupPoint={
                                  activeDestinationPickupPoint
                                }
                                onChange={newPickupPoint => {
                                  setReferences(
                                    REFERENCE_NAME.DELIVERY_PICKUP,
                                    newPickupPoint
                                  );
                                  form.change(
                                    SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                    PickupHelper.convertPickupToFormValues(
                                      newPickupPoint
                                    )
                                  );
                                }}
                                initialPostcodeValue={get(
                                  values,
                                  SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                                )}
                              />
                            </Grid>
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.DOOR}
                                title={STRINGS.DELIVER_TO_RECIPIENT}
                                bodyText={
                                  STRINGS.DELIVER_TO_RECIPIENT_DESCRIPTION
                                }
                                price='Free' // NOTE: Price is changing based on previous step, verify logic
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.DOOR);
                                  form.change(
                                    SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                    undefined
                                  );
                                  setReferences(
                                    REFERENCE_NAME.DELIVERY_PICKUP,
                                    null
                                  );
                                }}
                                disabled={parcelDestinationDisabled}
                              />
                            </Grid>
                            {activeDestinationPickupPoint && (
                              <Grid container item md={12} xs={12}>
                                <PickupPointWidget
                                  pickupPoint={activeDestinationPickupPoint}
                                  pickupPointLabel={STRINGS.CHOSEN_PICKUP_POINT}
                                  showAddressInLine
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Field>
                      <OnChange name={SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                        {value => {
                          const dropAtShop =
                            value === POINT_TYPE.SHOP &&
                            get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) ===
                              POINT_TYPE.SHOP;
                          form.change(
                            SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                            dropAtShop ? true : undefined
                          );
                        }}
                      </OnChange>
                    </FormSection>
                    <FormSection
                      title={STRINGS.WOULD_YOU_LIKE_COLLECTION}
                      section={SECTION.COLLECT_FROM}
                      disabled={parcelCollectionDisabled}
                      related={[
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <Field name={SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, alignItems: 'stretch' }}
                          >
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.DOOR}
                                title={STRINGS.YES_COLLECT}
                                bodyText={STRINGS.YES_COLLECT_DESCRIPTION}
                                price={
                                  parcelCollectionAvailable
                                    ? `+${(minDoorPrice - minShopPrice).toFixed(
                                        2
                                      )}`
                                    : '-'
                                }
                                printerRequired
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.DOOR);
                                  setReferences(
                                    REFERENCE_NAME.COLLECTION_PICKUP,
                                    null
                                  );
                                }}
                                disabled={
                                  parcelCollectionDisabled ||
                                  !parcelCollectionAvailable
                                }
                              />
                            </Grid>
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.SHOP}
                                highlightChip={STRINGS.MOST_POPULAR}
                                title={STRINGS.NO_DROP_OFF}
                                bodyText={STRINGS.NO_DROP_OFF_DESCRIPTION}
                                price='Free'
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.SHOP);
                                }}
                                disabled={parcelCollectionDisabled}
                              />
                              <PickupModal
                                label={STRINGS.VIEW_NEARBY_PICKUPS}
                                id='view-collection-pickups'
                                variant='text'
                                disabled={
                                  parcelCollectionDisabled ||
                                  input.value !== POINT_TYPE.SHOP
                                }
                                selectedPickupPoint={
                                  activeCollectionPickupPoint
                                }
                                onChange={newPickupPoint => {
                                  setReferences(
                                    REFERENCE_NAME.COLLECTION_PICKUP,
                                    newPickupPoint
                                  );

                                  form.change(
                                    SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
                                    PickupHelper.convertPickupToFormValues(
                                      newPickupPoint
                                    )
                                  );
                                }}
                                initialPostcodeValue={get(
                                  values,
                                  SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
                                )}
                              />
                            </Grid>
                            {activeCollectionPickupPoint && (
                              <Grid container item md={12} xs={12}>
                                <PickupPointWidget
                                  pickupPoint={activeCollectionPickupPoint}
                                  pickupPointLabel={STRINGS.CHOSEN_PICKUP_POINT}
                                  showAddressInLine
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Field>
                      <OnChange name={SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                        {value => {
                          const dropAtShop =
                            value === POINT_TYPE.SHOP &&
                            get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY) ===
                              POINT_TYPE.SHOP;
                          form.change(
                            SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                            dropAtShop ? true : undefined
                          );
                        }}
                      </OnChange>
                    </FormSection>
                    {collectionDateAvailable && (
                      <FormSection
                        title={STRINGS.COLLECTION_DATE_HEADER}
                        section={SECTION.SHIPMENT_DATE}
                        disabled={parcelCollectionDisabled}
                      >
                        <Loader
                          form={form}
                          promiseFn={loadCollectionDates}
                          collectionCountryCode={collectionCountryCode}
                          collectionPostcode={collectionPostcode}
                        >
                          {collectionDates => (
                            <Box sx={{ mt: 2 }}>
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.SHIPMENT_DATE.KEY}
                                label={SHIPMENT_FIELDS.SHIPMENT_DATE.LABEL}
                                select
                                size='small'
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.SHIPMENT_DATE.KEY
                                  ]
                                }
                              >
                                {collectionDates.map((date, index) => (
                                  <MenuItem key={index} value={date}>
                                    {moment(date, DATE_TIME_FORMAT).format(
                                      'dddd Do MMMM YYYY'
                                    )}
                                  </MenuItem>
                                ))}
                              </TextFieldRFF>
                            </Box>
                          )}
                        </Loader>
                      </FormSection>
                    )}
                    <FormSection
                      title={STRINGS.WHEN_DELIVER}
                      section={SECTION.NETWORK}
                      disabled={networkDisabled}
                    >
                      {networkDisabled ? (
                        <Box sx={{ mt: 2 }}>
                          <ActionButton
                            title={STRINGS.AVAILABLE_SERVICES}
                            bodyText={STRINGS.WE_ARE_CALCULATING_SERVICES}
                            price='--,--'
                            disabled
                          />
                        </Box>
                      ) : (
                        <Field name={SHIPMENT_FIELDS.NETWORK_CODE.KEY}>
                          {({ input }) => (
                            <Grid
                              container
                              spacing={2}
                              sx={{ my: 2, alignItems: 'stretch' }}
                            >
                              {filteredNetworks.map(network => (
                                <Grid
                                  container
                                  item
                                  md={12 / filteredNetworks.length}
                                  xs={12}
                                  key={network.networkKey}
                                >
                                  <ActionButton
                                    selected={
                                      input.value === network.networkKey
                                    }
                                    // highlightChip={MOST_POPULAR}
                                    title={network.networkDesc}
                                    // bodyText='Standard delivery within 1 working day'
                                    price={
                                      getAdditionalPriceForNetwork({
                                        network,
                                        dropAtShop,
                                        minDoorPrice,
                                        minShopPrice,
                                      })
                                        ? `+${getAdditionalPriceForNetwork({
                                            network,
                                            dropAtShop,
                                            minDoorPrice,
                                            minShopPrice,
                                          })}`
                                        : 'Free'
                                    }
                                    cardOnclick={() => {
                                      input.onChange(network.networkKey);
                                      form.batch(() => {
                                        form.change(
                                          SHIPMENT_FIELDS.NETWORK_DESC.KEY,
                                          network.networkDesc
                                        );
                                        form.change(
                                          SHIPMENT_FIELDS.NETWORK_BUS_ZONE.KEY,
                                          network.busZone
                                        );
                                        form.change(
                                          SHIPMENT_FIELDS.LIABILITY.KEY,
                                          network.insurance
                                        );
                                        if (
                                          network.insurance &&
                                          !get(
                                            values,
                                            SHIPMENT_FIELDS.LIABILITY_VALUE.KEY
                                          )
                                        ) {
                                          form.change(
                                            SHIPMENT_FIELDS.LIABILITY_VALUE.KEY,
                                            FREE_PROTECTION_DEDUCTION
                                          );
                                        }
                                      });
                                    }}
                                    disabled={networkDisabled}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </Field>
                      )}
                    </FormSection>
                    {insuranceVisible && (
                      <FormSection
                        title={STRINGS.WOULD_YOU_LIKE_INSURANCE}
                        section={SECTION.INSURANCE}
                      >
                        <Field name={SHIPMENT_FIELDS.LIABILITY_VALUE.KEY}>
                          {({ input }) => (
                            <Grid
                              container
                              spacing={2}
                              sx={{ mt: 2, alignItems: 'stretch' }}
                            >
                              <Grid container item lg={3} md={6} xs={12}>
                                <ActionButton
                                  selected={
                                    input.value === FREE_PROTECTION_DEDUCTION
                                  } // TODO: Add proper logic
                                  title='Cover up to £50'
                                  bodyText='Your delivery is automatically insured up to £50'
                                  price='Free'
                                  cardOnclick={() =>
                                    input.onChange(FREE_PROTECTION_DEDUCTION)
                                  }
                                />
                              </Grid>
                              <Grid container item lg={3} md={6} xs={12}>
                                <ActionButton
                                  selected={input.value === 100}
                                  title='Cover up to £100'
                                  bodyText='Your delivery will be insured up to £100'
                                  price='+1.00'
                                  cardOnclick={() => input.onChange(100)}
                                />
                              </Grid>
                              <Grid container item lg={3} md={6} xs={12}>
                                <ActionButton
                                  selected={input.value === 500}
                                  title='Cover up to £500'
                                  bodyText='Your delivery will be insured up to £500'
                                  price='+2.50'
                                  cardOnclick={() => input.onChange(500)}
                                />
                              </Grid>
                              <Grid container item lg={3} md={6} xs={12}>
                                <ActionButton
                                  selected={input.value === 1000}
                                  title='Cover up to £1000'
                                  bodyText='Your delivery will be insured up to £1000'
                                  price='+5.00'
                                  cardOnclick={() => input.onChange(1000)}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Field>
                      </FormSection>
                    )}
                  </form>
                  <Debugger>
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                  </Debugger>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
                    <SummaryDetails price={price} />
                    <Box
                      sx={{
                        gap: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {!isSmallScreen && (
                        <Button
                          actionid={PARCEL_DETAILS.CLICK_BACK}
                          variant='outlined'
                          onClick={handleBack}
                          sx={{ flexGrow: 1 }}
                        >
                          {STRINGS.BACK}
                        </Button>
                      )}
                      <Button
                        variant='contained'
                        form='parcelDetailsForm'
                        type='submit'
                        disabled={invalid}
                        sx={{ flexGrow: 1, marginX: { xs: 3, md: 0 } }}
                      >
                        {STRINGS.NEXT_STEP}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            );
          }}
        </WizardForm>
      </Grid>
      <MaxParcelDimsModal
        open={maxDimsModalOpen}
        onClose={() => setMaxDimsModalOpen(false)}
      />
    </Trackable>
  );
};

export default ParcelDetails;
