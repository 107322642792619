import { round } from 'lodash';
import { Divider, Box, Typography } from '@mui/material';

import {
  SUMMARY_DETAILS,
  FREE,
  DROP_OFF_TO_PICKUP,
  DIRECT_TO_PICKUP,
} from '../../../constants/strings';
import { getReceiverContactName } from '../../../helpers/shipment';
import ParcelsDetails from './ParcelsDetails';

const CheckoutListRow = ({ label, price }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
    <Typography variant='body2'>{label}</Typography>
    <Typography variant='body2'>{price}</Typography>
  </Box>
);

const CollectDeliveryDetails = ({ dropOffDetails, pickupDetails }) => (
  <>
    {dropOffDetails ? (
      <CheckoutListRow label={DROP_OFF_TO_PICKUP} price={FREE} />
    ) : (
      <CheckoutListRow label={SUMMARY_DETAILS.COLLECT_PARCEL} price={'5.00'} /> // TODO: add collection price
    )}
    {pickupDetails ? (
      <CheckoutListRow label={DIRECT_TO_PICKUP} price={FREE} />
    ) : (
      <CheckoutListRow
        label={SUMMARY_DETAILS.DELIVER_RECIPIENT}
        price={'2.00'} // TODO: add delivery price
      />
    )}
  </>
);

const CheckoutListItem = ({ basketItem, isLastItem, showMoreDetails }) => {
  const { liabilityAmount, totalIncVatAmount } = basketItem?.price || {};
  const receiverContactName = getReceiverContactName(basketItem?.shipment);
  const { parcels, _networkDesc, pickupDetails, _dropOffDetails } =
    basketItem?.shipment?.outboundConsignment || {};

  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }}>
        <ParcelsDetails
          receiverContactName={receiverContactName}
          parcels={parcels}
          networkDesc={_networkDesc}
          showMoreDetails={showMoreDetails}
          price={round(totalIncVatAmount, 2) || FREE}
        />

        {showMoreDetails && (
          <CollectDeliveryDetails
            pickupDetails={pickupDetails}
            dropOffDetails={_dropOffDetails}
          />
        )}

        <CheckoutListRow
          label={SUMMARY_DETAILS.INSURANCE_COVER}
          price={round(liabilityAmount, 2) || FREE}
        />
      </Box>

      <Divider sx={{ display: isLastItem ? 'none' : 'block' }} />
    </>
  );
};

const CheckoutList = ({ items = [] }) => (
  <>
    {items.map((basketItem, index) => (
      <CheckoutListItem
        key={basketItem.basketItemId}
        basketItem={basketItem}
        isLastItem={items.length === index + 1}
        showMoreDetails={items.length === 1}
      />
    ))}
  </>
);

export default CheckoutList;
