import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';

import { parcelsApis } from '../apis';
import { normalizeParcelNumber } from '../utils/parcel';
import { PARCEL_ERROR_BAD_REQUEST } from '../constants/strings';

const initialState = {
  status: 'idle',
  data: {},
};

const fetchParcel = createAsyncThunk(
  'parcel/fetchByNumber',
  async (parcelNumber, { rejectWithValue }) => {
    try {
      const response = await parcelsApis.getParcelByNumber(
        normalizeParcelNumber(parcelNumber)
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.error.statusCode === HttpStatusCode.BadRequest
        ) {
          return rejectWithValue({
            ...error.response.data.error,
            message: PARCEL_ERROR_BAD_REQUEST,
          });
        }

        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

const slice = createSlice({
  name: 'parcel',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParcel.fulfilled, (state, { payload }) => {
        state.data[normalizeParcelNumber(payload.parcelNumber)] = payload;
        state.status = 'success';
      })
      .addCase(fetchParcel.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchParcel.rejected, state => {
        state.status = 'failure';
      });
  },
});

const getParcelState = state => state.parcel;

const getParcel = parcelNumber =>
  createSelector(getParcelState, state => ({
    data: state.data[parcelNumber],
    status: state.status,
  }));

export default {
  reducer: slice.reducer,
  actions: {
    fetchParcel,
  },
  selectors: {
    getParcel,
  },
};
