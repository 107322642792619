import { useCallback } from 'react';
import { useParams } from 'react-router';
import { Button, Grid, Typography, useTheme } from '@mui/material';

import { ordersApis } from '../../apis';
import Loader from '../../features/Loader';
import Error from '../Auth/Error';
import {
  ORDER_SUMMARY,
  VIEW_YOUR_ORDERS,
  VIEW_INSTRUCTIONS,
} from '../../constants/strings';
import ShipmentCard from '../../components/ShipmentCard';

const OrderSummary = () => {
  const { orderId } = useParams();
  const theme = useTheme();

  const loadData = useCallback(async ({ orderId }) => {
    const { data } = await ordersApis.getOrderById(orderId);
    return data.shipments;
  }, []);

  return (
    <Loader
      promiseFn={loadData}
      orderId={orderId}
      errorComponent={error => <Error values={{ error }} />}
    >
      {shipments => (
        <Grid
          container
          item
          sx={{
            background: theme.palette.primary.pageBackground,
            flexGrow: 1,
            justifyContent: 'center',
            pb: 6,
          }}
        >
          <Grid
            xs={12}
            md={8}
            sx={{
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h2'
              textAlign='center'
              color='primary.main'
              sx={{ py: 3 }}
            >
              {ORDER_SUMMARY}
            </Typography>
            <Typography variant='h3' textAlign='center'>
              {VIEW_YOUR_ORDERS}
            </Typography>

            {shipments.map((shipment, index) => (
              <ShipmentCard
                key={index}
                shipment={shipment}
                header={
                  <Button
                    href={`/shipments/${shipment.shipmentId}`}
                    variant='contained'
                  >
                    {VIEW_INSTRUCTIONS}
                  </Button>
                }
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default OrderSummary;
