import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Box, Button, Typography } from '@mui/material';
import {
  countriesSelectors,
  countriesActions,
} from '../../../../redux/countriesSlice';
import {
  addressBookSchema,
  addressBookContactSchema,
  validateWithJoi,
} from '../../../../utils/validators';
import {
  normalizeAddressBookDataForPayload,
  normalizeFormValuesForValidation,
} from '../../../../utils/addressBook';

import { ADDRESSES_SECTION } from '../../../../constants/strings';
import { ADDRESS_BOOK_FIELDS } from '../../../../constants/forms';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { DEFAULT_PAGINATION_SEARCH_PAGE_SIZE } from '../../../../constants/pagination';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import AddressDetailsForm from '../../../../components/AddressDetailsForm';
import ContactDetailsForm from '../../../../components/ContactDetailsForm';

const AddressBookForm = ({
  isEditMode,
  onSidebarClose,
  currentPage,
  setPaginationParameters,
  setTotalPages,
  searchPageSize = DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
  resetSearch,
}) => {
  const [isFromDirty, setIsFormDirty] = useState(false);
  const countries = useSelector(countriesSelectors.getCountries);
  const dispatch = useDispatch();

  const { showSuccess } = useCustomSnackbar();
  const { errorHandler } = useErrorHandler();

  const initialFormState = useSelector(
    addressBooksSlice.selectors.getSelectedAddressBook
  );

  const onSubmit = useCallback(
    async formData => {
      const addressBookData = normalizeAddressBookDataForPayload({
        data: formData,
        isUpdate: isEditMode,
      });

      onSidebarClose();

      try {
        if (isEditMode) {
          await dispatch(
            addressBooksSlice.actions.updateAddressBook({
              addressBookId: initialFormState.addressBookId,
              addressBookData,
            })
          ).unwrap();
        } else {
          await dispatch(
            addressBooksSlice.actions.createAddressBook(addressBookData)
          ).unwrap();
        }

        const data = await dispatch(
          addressBooksSlice.actions.fetchAddressBooks({
            searchPage: currentPage,
            searchPageSize: searchPageSize,
          })
        ).unwrap();

        setPaginationParameters({
          currentPage: data.totalPages,
        });

        setTotalPages(data.totalPages);

        dispatch(addressBooksSlice.actions.resetSelectedAddressBook());

        showSuccess({
          message: isEditMode
            ? ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.UPDATE
            : ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.CREATE,
          variant: 'default',
        });
      } catch (error) {
        errorHandler(
          isEditMode
            ? ADDRESSES_SECTION.NOTIFICATIONS.ERROR.UPDATE
            : ADDRESSES_SECTION.NOTIFICATIONS.ERROR.CREATE,
          error,
          'AddressBookForm'
        );
      } finally {
        resetSearch();
      }
    },
    [
      currentPage,
      dispatch,
      errorHandler,
      initialFormState.addressBookId,
      isEditMode,
      onSidebarClose,
      showSuccess,
      setPaginationParameters,
      resetSearch,
    ]
  );

  useEffect(() => {
    if (!countries?.length) {
      dispatch(countriesActions.fetchCountries());
    }
  }, [countries?.length, dispatch]);

  return (
    <Box
      sx={{
        padding: '32px 16px 24px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h3'>
        {isEditMode
          ? ADDRESSES_SECTION.SIDEBAR_FORM.EDIT_ADDRESS
          : ADDRESSES_SECTION.SIDEBAR_FORM.ADD_NEW_ADDRESS}
      </Typography>
      <Box
        sx={{
          mt: 1,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={initialFormState}
          validate={values =>
            validateWithJoi(
              normalizeFormValuesForValidation(values),
              addressBookSchema
            )
          }
          render={({
            handleSubmit,
            form,
            pristine,
            invalid,
            submitting,
            values,
          }) => {
            // TODO: fix code style
            const { address, ...contactValues } = values;

            return (
              <Box
                sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    gap: 2,
                    py: 2,
                  }}
                >
                  <form id='addressBookSidebarForm' onSubmit={handleSubmit}>
                    <AddressDetailsForm
                      values={values}
                      config={{
                        fields: {
                          country: {
                            name: ADDRESS_BOOK_FIELDS.COUNTRY.KEY,
                            label: ADDRESS_BOOK_FIELDS.COUNTRY.LABEL,
                            required: true,
                          },
                          postcode: {
                            name: ADDRESS_BOOK_FIELDS.POSTCODE.KEY,
                            label: ADDRESS_BOOK_FIELDS.POSTCODE.LABEL,
                            required: true,
                          },
                          organisation: {
                            name: ADDRESS_BOOK_FIELDS.ORGANISATION.KEY,
                            label: ADDRESS_BOOK_FIELDS.ORGANISATION.LABEL,
                          },
                          street: {
                            name: ADDRESS_BOOK_FIELDS.ADDRESS.KEY,
                            label: ADDRESS_BOOK_FIELDS.ADDRESS.LABEL,
                            required: true,
                          },
                          locality: {
                            name: ADDRESS_BOOK_FIELDS.LOCALITY.KEY,
                            label: ADDRESS_BOOK_FIELDS.LOCALITY.LABEL,
                          },
                          town: {
                            name: ADDRESS_BOOK_FIELDS.CITY.KEY,
                            label: ADDRESS_BOOK_FIELDS.CITY.LABEL,
                            required: true,
                          },
                          county: {
                            name: ADDRESS_BOOK_FIELDS.COUNTY.KEY,
                            label: ADDRESS_BOOK_FIELDS.COUNTY.LABEL,
                          },
                        },
                        inputSize: 'small',
                        hasActionButtons: false,
                        idPrefix: 'addressBookSidebarForm',
                      }}
                    />
                    <ContactDetailsForm
                      sx={{ mt: 2 }}
                      values={contactValues}
                      labels={{
                        contactName: ADDRESS_BOOK_FIELDS.CONTACT_NAME.LABEL,
                        organisation: ADDRESS_BOOK_FIELDS.ORGANISATION.LABEL,
                        nickname: ADDRESS_BOOK_FIELDS.NICKNAME.LABEL,
                        email: ADDRESS_BOOK_FIELDS.EMAIL.LABEL,
                        phoneNumber: ADDRESS_BOOK_FIELDS.PHONE_NUMBER.LABEL,
                      }}
                      inputSize='small'
                      onChange={(fieldName, formValues) => {
                        form.change(fieldName, formValues[fieldName]);
                      }}
                      schema={addressBookContactSchema}
                      form={form}
                      isEditMode={isEditMode}
                    />
                  </form>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Button
                    sx={{ flex: 1 }}
                    variant='outlined'
                    onClick={onSidebarClose}
                  >
                    {ADDRESSES_SECTION.SIDEBAR_FORM.CANCEL}
                  </Button>
                  <Button
                    sx={{ ml: 2, flex: 1 }}
                    variant='contained'
                    form='addressBookSidebarForm'
                    type='submit'
                    disabled={
                      (!isFromDirty && pristine) || submitting || invalid
                    }
                  >
                    {ADDRESSES_SECTION.SIDEBAR_FORM.SAVE}
                  </Button>
                </Box>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

AddressBookForm.propTypes = {
  isEditMode: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  setPaginationParameters: PropTypes.func.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  resetSearch: PropTypes.func,
};

export default AddressBookForm;
