import { Box, Paper, Checkbox, Typography, Link } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { SALES_CONDITIONS } from '../../constants/strings';

const SalesConditions = ({ onSetCheck }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
    onSetCheck(event.target.checked);
  };

  return (
    <Box>
      <Paper sx={{ p: 2, my: 2 }}>
        <Typography variant='h3'>{SALES_CONDITIONS.TITLE}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            sx={{ color: 'primary.main' }}
          />
          <Typography
            variant='body1'
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.4px',
            }}
          >
            {SALES_CONDITIONS.ACCEPT_THE}
            <Link
              href='#'
              sx={{
                textDecoration: 'none',
              }}
            >
              {SALES_CONDITIONS.TERMS_AND_CONDITIONS}
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

SalesConditions.propTypes = {
  onSetCheck: PropTypes.func.isRequired,
};

export default SalesConditions;
