import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import useAnalytics from '../../utils/useAnalytics';
import { BASKET } from '../../constants/analytics';
import ShareModal from '../../components/ShareModal/ShareModal';
import { COLLECTION_STEPS_CONFIG } from './config';

const Collection = ({ shipment }) => {
  const theme = useTheme();
  const [openShareModal, setOpenShareModal] = useState(false);
  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.contrastText, pb: 4 }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12}>
              <Grid sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'start',
                      padding: theme.spacing(2),
                      gap: theme.spacing(2),
                    }}
                  >
                    {COLLECTION_STEPS_CONFIG(shipment).map(step => (
                      <Box
                        key={step.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          marginBottom: theme.spacing(2),
                          gap: theme.spacing(2),
                        }}
                      >
                        <step.icon style={{ width: '35px', height: '35px' }} />
                        <Typography
                          variant='body2'
                          sx={{ display: 'inline-flex' }}
                        >
                          <span style={{ marginRight: theme.spacing(1) }}>
                            {step.id}.
                          </span>
                          <span>{step.text}</span>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

Collection.propTypes = {
  shipment: PropTypes.object,
};

export default Collection;
