import moment from 'moment-timezone';

import { DATE_FORMAT } from '../constants';
import { format } from 'date-fns';

export const formattedDayOfTheWeekDayMonthShipmentDate = value => {
  return value
    ? format(new Date(value), DATE_FORMAT.DAY_OF_WEEK_DAY_MONTH_FORMAT)
    : '';
};

export const getUKCurrentMomentTime = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);
