import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ADDRESSES_SECTION } from '../../../constants/strings';
import { SectionLayout } from '../components';
import addressBooksSlice from '../../../redux/addressBooksSlice';
import Loader from '../../../features/Loader/Loader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useBreakpoint from '../../../hooks/useBreakpoint';
import useAddressBookSearch from '../../../hooks/useAddressBookSearch';
import { ADDRESS_BOOK_TABLE_CONFIG } from '../../../constants/table';
import { usePaginationParameters } from '../../../hooks/usePaginationParameters';
import { DEFAULT_PAGINATION_SEARCH_PAGE_SIZE } from '../../../constants/pagination';
import {
  AddressBookTable,
  AddressBookBulkUploadModal,
  AddressBookSearch,
} from './components';

const AddressesSection = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const {
    currentPage,
    searchPageSize,
    setPaginationParameters,
    totalPages,
    setTotalPages,
  } = usePaginationParameters({
    defaultPageSize: DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
  });
  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();

  const isSmallScreen = useBreakpoint('md');
  const {
    isSearchMode,
    searchString,
    searchTrigger,
    handleSearchChange,
    handleSearchSubmit,
    resetSearch,
  } = useAddressBookSearch({ pageSize: DEFAULT_PAGINATION_SEARCH_PAGE_SIZE });

  const addressBooks = useSelector(
    addressBooksSlice.selectors.getAddressBooksByPage
  );

  const fetchAddressBooks = useCallback(async () => {
    let data;
    try {
      if (isSearchMode) {
        data = await dispatch(
          addressBooksSlice.actions.searchAddressBooks({
            searchPage: currentPage,
            searchPageSize,
            searchString,
          })
        ).unwrap();
      } else {
        data = await dispatch(
          addressBooksSlice.actions.fetchAddressBooks({
            searchPage: currentPage,
            searchPageSize,
          })
        ).unwrap();
      }

      setTotalPages(data.totalPages);
    } catch (error) {
      errorHandler(ADDRESSES_SECTION.NOTIFICATIONS.ERROR.FETCHING_ERROR, error);
    }
    // Do not add errorHandler to dependencies it causes an infinite loop
  }, [
    isSearchMode,
    searchTrigger,
    setTotalPages,
    dispatch,
    currentPage,
    searchPageSize,
  ]);

  return (
    <SectionLayout
      title={ADDRESSES_SECTION.SECTION_TITLE}
      actionButtons={
        <Box
          sx={{
            marginTop: { xs: 1, sm: 0 },
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              fontSize: '13px',
              flexGrow: { xs: 1, md: 0 },
            }}
            variant='outlined'
            endIcon={<FileUploadOutlinedIcon />}
            onClick={() => setIsBulkUpdateModalOpen(true)}
          >
            {ADDRESSES_SECTION.BULK_UPLOAD}
          </Button>
          <Button
            sx={{
              ml: { xs: 1, md: 2 },
              fontSize: '13px',
              flexGrow: { xs: 1, md: 0 },
            }}
            variant='outlined'
            onClick={() => {
              setIsEditMode(false);
              setSidebarOpen(true);
            }}
          >
            {ADDRESSES_SECTION.ADD_NEW_ADDRESS}
          </Button>
        </Box>
      }
      content={
        <>
          <AddressBookSearch
            inputWidth={isSmallScreen ? '220px' : '353px'}
            searchString={searchString}
            onSearchChange={handleSearchChange}
            onSearchSubmit={handleSearchSubmit}
          />
          <Loader
            promiseFn={fetchAddressBooks}
            pendingProps={{
              sx: { marginTop: { xs: '50px', md: '100px' } },
            }}
          >
            <AddressBookTable
              rows={addressBooks}
              currentPage={currentPage}
              setPaginationParameters={setPaginationParameters}
              setTotalPages={setTotalPages}
              config={ADDRESS_BOOK_TABLE_CONFIG}
              searchPageSize={searchPageSize}
              isSidebarOpen={isSidebarOpen}
              isEditMode={isEditMode}
              isSearchMode={isSearchMode}
              resetSearch={resetSearch}
              onEditRowHandler={value => {
                setIsEditMode(value);
                setSidebarOpen(value);
              }}
            />
          </Loader>
        </>
      }
      currentPage={currentPage}
      totalPages={totalPages}
      onPaginationChange={setPaginationParameters}
    >
      <AddressBookBulkUploadModal
        isOpen={isBulkUpdateModalOpen}
        onClose={() => setIsBulkUpdateModalOpen(false)}
        currentPage={currentPage}
        setTotalPages={setTotalPages}
        resetSearch={resetSearch}
      />
    </SectionLayout>
  );
};

export default AddressesSection;
